import React from "react";
import "./lib/intigriti/Intigriti.css";
import { transformCurrencies } from "./utils/transformers.js";
import TextBlock from "./components/TextBlock.js";
import Calculator from "./components/Calculator.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import industriesData from "./data/industries.json";
import currenciesData from "./data/currencies.json";
import "@intigriti/masthead/intigriti-navigation";
import "@intigriti/masthead/intigriti-header";
import "@intigriti/masthead/intigriti-footer";

import { ThemeProvider, createTheme } from "@mui/material/styles";

library.add(fab, fas, far);

const theme = createTheme({
  palette: {
    primary: {
      main: "#4c59a8",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

function App() {
  let transformedCurrencyData = transformCurrencies(currenciesData);
  const websiteUrl = "https://www.intigriti.com";
  const appUrl = "https://app.intigriti.com";

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="header">
          <intigriti-navigation website-url={websiteUrl} app-url={appUrl} />
          <intigriti-header website-url={websiteUrl} app-url={appUrl} />
        </div>
        <TextBlock />
        <Calculator
          industries={industriesData}
          currencies={transformedCurrencyData}
        />
        <intigriti-footer website-url={websiteUrl} app-url={appUrl} />
      </div>
    </ThemeProvider>
  );
}

export default App;
