const sliderMarksMaturity = [
    { value: 0, label: "Basic", color: "rgb(0, 128, 0)" }, // Green
    { value: 25, label: "Intermediate", color: "rgb(173, 255, 47)" }, // Green-Yellow
    { value: 50, label: "Good", color: "rgb(255, 255, 0)" }, // Yellow
    { value: 75, label: "Proactive", color: "rgb(255, 165, 0)" }, // Orange
    { value: 100, label: "Advanced", color: "rgb(139, 0, 0)" } // Dark Red
];

const sliderMarksRisk = [
    { value: 0, label: "Very low", color: "rgb(0, 128, 0)" }, // Green
    { value: 25, label: "Low", color: "rgb(255, 255, 0)" }, // Yellow
    { value: 50, label: "Medium", color: "rgb(255, 140, 0)" }, // Dark Orange
    { value: 75, label: "High", color: "rgb(255, 69, 0)" }, // Red-Orange
    { value: 100, label: "Very high", color: "rgb(139, 0, 0)" } // Dark Red
];

const skillLevelValues = [
    {
        "title": "beginner",
        "minBounty": 0,
        "color": "#ce1c51"
    },
    {
        "title": "novice",
        "minBounty": 1000,
        "color": "#f03257"
    },
    {
        "title": "intermediate",
        "minBounty": 2500,
        "color": "#f69833"
    },
    {
        "title": "advanced",
        "minBounty": 5000,
        "color": "#e0ac00"

    },
    {
        "title": "expert",
        "minBounty": 7500,
        "color": "#02a87c"
    }
]


function getTierColor(tier) {
    let colors = `#4C59A8
    #7BCFDB
    #E0AC00
    #02A87C
    #F03157
    #E49D53`.split("\n");

    return colors[tier % (colors.length - 1)];
    //return `hsla(${tier * (320 / maxTiers) + 80}, 80%, 50%, 1)`;
}

function getStatColor(pct) {
    if (pct < -30) {
        return "#ce1c51"; //darkred
    }
    else if (pct < -20) {
        return "#f03257"; //red
    }
    else if (pct < -10) {
        return "#f69833"; //orange
    }
    else if (pct < 0) {
        return "#e0ac01"; //yellow
    }
    else {
        return "#02a87c"; //green
    }
}

function getSkillLevelValueForBounty(bounty) {
    let result;
    skillLevelValues.forEach(skillLevelValue => {
        if (skillLevelValue.minBounty <= bounty
            && (!result || result.minBounty <= skillLevelValue.minBounty)
        ) {
            result = skillLevelValue;
        }
    });
    return result;
}

export { sliderMarksMaturity, sliderMarksRisk, skillLevelValues, getTierColor, getStatColor, getSkillLevelValueForBounty }




