const formatNumber = (number) => {
    if (isNaN(number)) {
        number = 0;
    }
    return Math.round(number).toLocaleString();
}

const transformCurrencies = (currencies) => {
    let transformedCurrencies = [];
    let supportedCurrencies = ["USD", "GBP", "EUR", "CAD", "SGD", "CHF", "DKK", "NOK", "SEK", "AUD", "JPY"];
    var currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };
    const currencyLocales = {
        'USD': 'en-US',
        'GBP': 'en-GB',
    }
    const defaultCurrencyCode = "EUR";

    Object.keys(currencies.rates).forEach(currencyCode => {
        let currency = currencies.rates[currencyCode];
        let isCurrencySelected = false;
        if (supportedCurrencies.indexOf(currencyCode) > -1) {
            let currencyObject = {
                code: currencyCode,
                symbol: currencySymbols[currencyCode],
                name: currency.currency_name,
                rate: currency.rate
            }
            if (currencyLocales[currencyCode] === navigator.language) {
                currencyObject.selected = true;
                isCurrencySelected = true;
            }
            transformedCurrencies.push(currencyObject);
        }
        if (!isCurrencySelected) { //if no currency is selected, select EUR
            transformedCurrencies.forEach(transformedCurrency => {
                if (transformedCurrency.code == defaultCurrencyCode) {
                    transformedCurrency.selected = true;
                }
            })
        }
    });
    return transformedCurrencies;
}


const convertValueToCurrency = (value, selectedCurrency) => {
    value = value * (1 / (selectedCurrency.rate ? selectedCurrency.rate : 1));
    return value;
}

const convertValuesToCurrency = (values, selectedCurrency) => {
    let convertedValues = [];
    values.forEach(value => {
        let convertedValue = convertValueToCurrency(value, selectedCurrency);
        convertedValues.push(convertedValue);
    });
    return convertedValues;
}

export { formatNumber, transformCurrencies, convertValuesToCurrency }
