import React, { useState, useRef } from 'react';
import { Popper, Paper, Typography, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import './ArrowTooltip.css';


const ArrowTooltip = ({ children }) => {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '20px',
            boxSizing: 'initial',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },

    }));

    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    {children}
                </React.Fragment>
            }
        >
            <FontAwesomeIcon icon={faInfoCircle} />

        </HtmlTooltip>
    );
};

export default ArrowTooltip;