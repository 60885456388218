
import { LineChart } from '@mui/x-charts';

function BountyChart({ industryMedians, industryAverages, tiers, includeExceptional }) {
    let series = [];
    // Update industryMedians array items
    industryMedians.forEach((item, index) => {
        industryMedians[index] = Math.round(item);
    });

    // Update industryAverages array items
    industryAverages.forEach((item, index) => {
        industryAverages[index] = Math.round(item);
    });

    series.push({
        label: `Industry average`,
        data: industryAverages,
        color: "#999999"
    });
    series.push({
        label: `Industry median`,
        data: industryMedians,
        color: "#222222"
    })

    tiers.forEach(tier => {
        tier.bounties.forEach(bounty => { //Round
            bounty = Math.round(bounty);
        });
        series.push({
            label: tier.name,
            data: tier.bounties,
            color: tier.color
        })
    })

    let xAxis = [
        {
            scaleType: 'point',
            data: ["Low", "Medium", "High", "Critical"]
        }
    ]

    if (includeExceptional) {
        xAxis[0].data.push("Exceptional");
    }

    return (
        <LineChart skipAnimation
            xAxis={xAxis}
            series={series}
        />);
}

export default BountyChart;
